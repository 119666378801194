@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap");
@import "./styles/questionPreview";

$primary-color: #181c62;
$secondary-color: #327091;

:root {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-main {
  height: 100vh;
  width: 100vw;
}

.iframe-container {
  height: 600px;
  width: 100%;
  border: none;
}

::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

// /* Track */
// ::-webkit-scrollbar-track {
//   // background: #fff;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

input::-ms-reveal {
  display: none;
}

a {
  text-decoration: none;
}

.quill-container {
  .ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    // border: 1px solid rgba(0, 0, 0, 0.87);
  }
  .ql-container.ql-snow {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    // border: 1px solid rgba(0, 0, 0, 0.87);
    .ql-editor {
      min-height: 150px;
    }
    .ql-editor.ql-blank::before {
      font-style: normal;
      color: lightgray;
      font-size: 15px;
    }
  }
  .ql-snow {
    .ql-picker {
      &.ql-size {
        .ql-picker-label:not(.ql-active) {
          &::before {
            content: "16px";
          }
        }
        .ql-picker-label,
        .ql-picker-item {
          &::before {
            content: attr(data-value);
          }
        }
      }
    }
  }
  .ql-container {
    font-size: 1rem;
    .ql-size-12px {
      font-size: 0.75rem;
    }
    .ql-size-14px {
      font-size: 0.875rem;
    }
    .ql-size-16px {
      font-size: 1rem;
    }
    .ql-size-18px {
      font-size: 1.125rem;
    }
    .ql-size-20px {
      font-size: 1.25rem;
    }
    .ql-size-24px {
      font-size: 1.5rem;
    }
    .ql-size-28px {
      font-size: 1.75rem;
    }
    .ql-size-36px {
      font-size: 2.25rem;
    }
  }
}

.micro-image {
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  object-fit: contain;
  border-radius: 8px;
}

.sidebar {
  height: 100vh;
  width: 240px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  padding: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .sider-menu {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 12px;
    color: #637e85;
    cursor: pointer;
    // .side-bar-icon {
    //   path {
    //     fill: #ffffff;
    //   }
    // }
  }
  .active.sider-menu {
    background-color: #e3f1f4;
    color: #327091;
    .MuiTypography-root {
      font-weight: 500;
    }
    // .side-bar-icon {
    //   .side-bar-icon-rect {
    //     fill: rgba(255, 255, 255, 0.1);
    //   }
    //   path {
    //     fill: #327091;
    //   }
    // }
  }
}

.rearrange-section {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
  margin-bottom: 12px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  .shuffle-handler {
    margin-right: 15px;
  }
}

.dot-flashing-wrapper {
  background: #ffffff;
  padding: 19px 0px 8px 31px;
  border-radius: 8px 0px 8px 8px;
  width: 75px;
  height: 58px;
  .dot-flashing {
    margin-top: 8px;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #4b5563;
    color: #4b5563;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.1s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -12.5px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #4b5563;
    color: #4b5563;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 12.5px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #4b5563;
    color: #4b5563;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #4b5563;
    }
    50%,
    100% {
      background-color: #f3f4f6;
    }
  }
}

.google-fit-ul {
  padding-left: 2rem;
  font-size: 1.125rem;
  li {
    margin-top: 1rem;
  }
}

.calendar-view {
  width: 100% !important;
  max-height: none !important;
  height: 100% !important;
  .MuiDateCalendar-viewTransitionContainer {
    flex: 1;
    & > div {
      height: 100%;
      & > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
      }
      .MuiDayCalendar-slideTransition {
        flex: 1;
        .MuiDayCalendar-monthContainer {
          display: flex;
          flex-direction: column;
          gap: 14px;
          padding-right: 8px;
          min-height: 100%;
        }
      }
    }
  }
  .MuiPickersCalendarHeader-root {
    padding: 0;
    padding-right: 8px;
    margin-bottom: 40px;
    .MuiPickersCalendarHeader-labelContainer {
      font-size: 24px;
      font-weight: 600;
      cursor: default;
    }
  }
  .MuiDayCalendar-header {
    justify-content: space-between;
    padding-right: 8px;
    .MuiDayCalendar-weekDayLabel {
      font-size: 18px;
      font-weight: 500;
      cursor: default;
      color: #333333;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(1)::after {
      content: "o";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(2)::after {
      content: "u";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(3)::after {
      content: "e";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(4)::after {
      content: "h";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(5)::after {
      content: "r";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(6)::after {
      content: "a";
      display: inline-block;
    }
    .MuiDayCalendar-weekDayLabel:nth-child(7)::after {
      content: "u";
      display: inline-block;
    }
  }

  .MuiDayCalendar-weekContainer {
    justify-content: space-between;
    .MuiPickersDay-root {
      font-size: 18px;
      font-weight: 600;
      color: #828282;
      border: 1.5px solid #828282;
    }

    .Mui-disabled {
      color: lightgrey;
      border-color: lightgrey;
    }

    .Mui-selected {
      background-color: $secondary-color;
      color: #ffffff;
      border: none;
      &:hover {
        background-color: $secondary-color;
      }
      &:focus {
        background-color: $secondary-color;
      }
    }
  }
  // @media (max-width: 1700px) {
  //   .MuiCalendarPicker-viewTransitionContainer {
  //     & > div {
  //       & > div {
  //         gap: 8px;
  //       }
  //       .MuiDayCalendar-slideTransition {
  //         .MuiDayCalendar-monthContainer {
  //           gap: 10px;
  //         }
  //       }
  //     }
  //   }
  //   .MuiPickersCalendarHeader-root {
  //     margin-bottom: 20px;

  //     .MuiPickersCalendarHeader-labelContainer {
  //       font-size: 18px;
  //       font-weight: 600;
  //     }
  //   }
  //   .MuiDayCalendar-weekContainer {
  //     .MuiPickersDay-root {
  //       font-size: 14px;
  //       width: 28px;
  //       height: 28px;
  //     }
  //   }
  //   .MuiDayCalendar-header {
  //     .MuiDayCalendar-weekDayLabel {
  //       font-size: 16px;
  //     }
  //   }
  // }
  // @media (max-width: 1500px) {
  //   .MuiCalendarPicker-viewTransitionContainer {
  //     & > div {
  //       & > div {
  //         gap: 6px;
  //       }
  //       .MuiDayCalendar-slideTransition {
  //         .MuiDayCalendar-monthContainer {
  //           gap: 8px;
  //         }
  //       }
  //     }
  //   }
  //   .MuiPickersCalendarHeader-root {
  //     .MuiPickersCalendarHeader-labelContainer {
  //       font-size: 16px;
  //       font-weight: 600;
  //     }
  //   }
  //   .MuiDayCalendar-weekContainer {
  //     .MuiPickersDay-root {
  //       font-size: 12px;
  //       width: 26px;
  //       height: 26px;
  //       border-width: 1px;
  //     }
  //   }
  //   .MuiDayCalendar-header {
  //     .MuiDayCalendar-weekDayLabel {
  //       font-size: 14px;
  //     }
  //   }
  // }
}

.EmojiPickerReact {
  .epr-category-nav {
    padding: 0 10px 10px 10px;
  }
  .epr-emoji-category-label {
    position: static !important;
  }
}

:picture-in-picture {
  display: none !important;
}
